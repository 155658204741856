import React, {useContext, useEffect, useState} from "react";
import {AxiosError, AxiosResponse} from "axios";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment/moment";
import {Box, Button, CircularProgress, CircularProgressProps, Typography} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {useMutation, useQuery} from "@tanstack/react-query";
import * as yup from "yup";

import hubspot from './../../../../assets/images/hubspot.svg'
import FormikMUISimpleInput from "../../../../components/Formik/MUISimpleInput/FormikMUISimpleInput";
import {notifyError, notifyInfo, notifySuccess} from "../../../../components/utils/ToastNotifications/Notifier";
import {ENDPOINTS} from "../../../../constants";
import PathContext from "../../../../store/context/path-context";
import {useAPI} from "../../../../utils/hooks/useAPI";
import {info_icon_style, upload_icon_style} from "../ImportCSVModal/ImportCSVModal";
import './../MemberManagement.scss';
import './ImportHubSpotDataModal.scss';


type HubSpotModalProps = {
  handleHubSpotSubmit: Function;
  importStats: ImportStatus;
  setImportStats: React.Dispatch<React.SetStateAction<ImportStatus>>,
  shouldRefetch: boolean;
  setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface TokenData {
  id: number,
  token: string,
  user_email: string,
  created_at: string
}

export interface ImportStatus {
  is_import_finished: boolean,
  total: number,
  completed: number
}

export const invisible_icon_style = {
  fontSize: "1.83rem",
  marginTop: "0.3rem",
  marginRight: "0.3rem",
  marginLeft: "0.3rem",
  cursor: "pointer",
  color: "rgba(189, 199, 207, 1)"
}

export const visible_icon_style = {
  fontSize: "1.83rem",
  marginTop: "0.3rem",
  marginRight: "0.3rem",
  marginLeft: "0.3rem",
  cursor: "pointer",
};

export const quick_quide_icon_style = {
  fontSize: "1.58rem",
  color: "#2DAAE2",
  paddingRight: "0.3rem",
  paddingTop: "0.5rem",
  paddingLeft: "0.8rem"
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      <CircularProgress variant="determinate" {...props} className="circular-progress-element"/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          className="typography-element"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const HubSpotModal = ({
                        handleHubSpotSubmit,
                        importStats,
                        setImportStats,
                        setShouldRefetch,
                        shouldRefetch
                      }: HubSpotModalProps) => {
  const api = useAPI();
  const [isHubspotTokenExist, setIsHubspotTokenExist] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [isEditButtonClicked, setIsEditButtonClicked] = useState<boolean>(false);
  const [isImportButtonClicked, setIsImportButtonClicked] = useState<boolean>(false);
  const [tokenData, setTokenData] = useState<TokenData>();
  const [progress, setProgress] = useState(0);
  const [isMembersImportStarted, setIsMembersImportStarted] = useState<boolean>(false);
  const [isMembersImportFinished, setIsMembersImportFinished] = useState<boolean>(false);
  const {apiUrlProps: {tenant}} = useContext(PathContext);

  const {data: hubspotTokenData} = useQuery({
    queryKey: ['getHubspotToken'],
    queryFn: getHubspotToken,
    refetchOnWindowFocus: false,
  })

  function getHubspotToken(): Promise<AxiosResponse<TokenData>> {
    return api.get(ENDPOINTS.HUBSPOT_APP.get_or_create_hubspot_token)
  }

  useEffect(() => {
    if (hubspotTokenData) {
      setIsHubspotTokenExist(true);
      setTokenData(hubspotTokenData.data)
    }
  }, [hubspotTokenData?.data])

  const handleCreatingHubspotToken = (values: { token: string }) => {
    return api.post(ENDPOINTS.HUBSPOT_APP.get_or_create_hubspot_token, values);
  }

  const {mutate: createHubspotToken} = useMutation({
    mutationFn: handleCreatingHubspotToken,
    onSuccess: async (response) => {
      notifySuccess('Your token has been successfully added.');
      setIsHubspotTokenExist(true);
      setTokenData(response.data);
      formik.resetForm();
    },
    onError: (error) => {
      notifyError((error as AxiosError).response?.data?.message || 'There was a problem adding your token. Please try again later.');
      formik.resetForm();
    },
  })

  const handleEditingHubspotToken = (values: { token: string }) => {
    return api.patch(ENDPOINTS.HUBSPOT_APP.edit_token(String(tokenData ? tokenData.id : 0)), values);
  }

  const {mutate: editHubspotToken} = useMutation({
    mutationFn: handleEditingHubspotToken,
    onSuccess: async (response) => {
      notifySuccess('Your token has been successfully changed.');
      setIsHubspotTokenExist(true);
      setTokenData(response.data);
      formik.resetForm();
    },
    onError: (error) => {
      notifyError((error as AxiosError).response?.data?.message || 'There was a problem adding your token. Please try again later.');
      formik.resetForm();
    },
  })

  const handleStartMembersImport = () => {
    return api.post(ENDPOINTS.HUBSPOT_APP.start_members_import)
  }

  const {mutate: startMembersImport} = useMutation({
    mutationFn: handleStartMembersImport,
    onSuccess: async (response) => {
      notifySuccess('Members import started.');
      setIsMembersImportStarted(true)
    },
    onError: (error) => {
      notifyError((error as AxiosError).response?.data?.message || 'There was a problem importing your data. Please try again later.');
    },
  })

  const {refetch: refetchExportedImportErrorsData} = useQuery({
    queryKey: ['export-errors-data'],
    queryFn: () => handleFetchingImportErrorsData(),
    enabled: false
  })

  const handleFetchingImportErrorsData = () => {
    return api.get(ENDPOINTS.HUBSPOT_APP.get_errors_data)
  }

  const handleDownloadCsvFile = async () => {
    try {
      const {data} = await refetchExportedImportErrorsData();

      if (data) {
        let blob = new Blob([(data as any).data], {
          type: "application/pdf"
        });
        const fileURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.download = `${tenant}_import_errors_${moment().format('YYYY-MM-DD')}.csv`
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      notifyError('Something went wrong.');
    }
  };

  const {data: importStatusData, refetch} = useQuery({
    queryKey: ['importStatusData'],
    queryFn: getImportStatus,
    enabled: isMembersImportStarted,
    refetchInterval: 5000,
    refetchOnWindowFocus: false,
  })

  function getImportStatus(): Promise<AxiosResponse<ImportStatus>> {
    return api.get(ENDPOINTS.HUBSPOT_APP.get_import_status)
  }

  const {refetch: resetStatusData} = useQuery({
    queryKey: ['resetStatusData'],
    queryFn: resetImportState,
    enabled: false,
  })

  function resetImportState() {
    return api.get(ENDPOINTS.HUBSPOT_APP.reset_import_state)
  }

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      setShouldRefetch(false);
    }
  }, [shouldRefetch, refetch, setShouldRefetch]);

  useEffect(() => {
    const fetchData = async () => {
      if (importStatusData?.data && !shouldRefetch) {
        const totalRecords = importStatusData.data.total;
        const completedRecords = importStatusData.data.completed;
        const progressValue = (completedRecords / totalRecords) * 100;
        setProgress(progressValue);
        setImportStats(importStatusData.data);
        if (importStatusData.data.is_import_finished) {
          localStorage.setItem('userModalInput', 'true');
          setIsMembersImportStarted(false);
          setIsMembersImportFinished(true);
          await resetStatusData()
          notifySuccess('Member import process has completed successfully.');
        }
      }
    };
    fetchData();
  }, [importStatusData?.data, setImportStats]);

  const formik = useFormik({
    initialValues: {
      token: tokenData ? tokenData.token : "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      token: yup.string().required("This field is required."),
    }),
    onSubmit: (values) => {
      if (tokenData && isEditButtonClicked) {
        editHubspotToken(values)
      } else {
        createHubspotToken(values)
      }
    }
  })

  return (
    <section className="quick-guide__modal-padding">
      <FormikProvider value={formik}>
        <Form className="flex flex-col member-management__hubspot-modal">
          <div className="member-management__csv-modal-title">Import data from HubSpot</div>
          <div className="quick-guide__main">
            <div>
              <InfoIcon style={quick_quide_icon_style}/>
            </div>
            <div>
              <p className="quick-guide__paragraph">
                A quick guide how to get your HubSpot Access token: <br/>
                1. Go to your HubSpot account and click on “Settings” <br/>
                2. Select “Integrations” then “Private Apps” <br/>
                3. Click on “Create Private App” and configure the basic info <br/>
              </p>
              <ul className="m-0 ml-1 p-0 font-bold quick-guide__ul-element">4. Click the “Scopes” tab and allow the
                following scopes: <br/>
                <li className="ml-12 font-bold">Companies - Read and Write</li>
                <li className="ml-12 font-bold">Pipelines Orders - Read and Write</li>
                <li className="ml-12 font-bold">Owners - Read</li>
              </ul>
              <p className="quick-guide__paragraph mt-0">5. Create your new app and copy the token.</p>
              <a href="https://developers.hubspot.com/docs/api/private-apps#create-a-private-app"
                 className="quick-guide__link" target="_blank" rel="noreferrer">
                More in HubSpot Integration Guide
              </a>
            </div>
          </div>
          <div className="quick-guide__subtitle">
            <img src={hubspot} alt="Hubspot Icon" style={info_icon_style}/>
            <h2 className="member-management__csv-modal-subtitle-content">
              Add your HubSpot Access token here:
            </h2>
          </div>
          <div className="mb-4 flex justify-center ">
            <div>
              {
                visible ? <VisibilityOutlinedIcon style={visible_icon_style} onClick={() => setVisible(false)}/>
                  : <VisibilityOffOutlinedIcon style={invisible_icon_style} onClick={() => setVisible(true)}/>
              }
            </div>
            <div className="w-full quick-guide__token-section">
              <FormikMUISimpleInput error={!!formik.errors.token} label="Token" name="token"
                                    size="small" placeholder="Token" type={visible ? "text" : "password"}
                                    disabled={!(isEditButtonClicked || !isHubspotTokenExist)}/>
            </div>
            <div>
              {
                isHubspotTokenExist &&
                  <EditIcon style={visible_icon_style} onClick={() => setIsEditButtonClicked(true)}/>
              }
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              variant="contained"
              size="large"
              className={formik.isSubmitting || !formik.isValid || !formik.dirty || !formik.touched ? "!font-bold !text-base !px-7 !rounded-lg"
                : "!font-bold !text-base !px-7 !rounded-lg member-management__buttons-main member-management__save-btn"}
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || !formik.touched || !(isEditButtonClicked || !isHubspotTokenExist)}
            >
              Save
            </Button>
          </div>
          <div
            className="quick-guide__subtitle">
            <FileUploadIcon style={upload_icon_style}/>
            <h2 className="member-management__csv-modal-subtitle-content">
              Import your HubSpot data</h2>
          </div>
          <div className="quick-guide__import-section">
            <div
              className="member-management__csv-modal-div member-modal__mobile-upload-margin quick-guide__import-div mb-2">
              <Button variant="contained"
                      size="large"
                      className={isImportButtonClicked ? "!font-bold !text-base !px-7 !rounded-lg" :
                        "!font-bold !text-base !px-7 !rounded-lg member-management__buttons-main member-management__upload-btn"}
                      onClick={() => {
                        notifyInfo('Fetching companies from HubSpot...');
                        startMembersImport();
                        setIsImportButtonClicked(true);
                      }}
                      disabled={isImportButtonClicked}
              >
                <span>IMPORT</span>
              </Button>
            </div>
            {importStats && importStats.total > 0 && (
              <div className="text-center ml-1.5 flex quick-guide__ul-element">
                {isMembersImportFinished ? <CheckCircleIcon style={{marginTop: "0.7rem", color: "#2fa84f"}}/> :
                  <CircularProgressWithLabel value={progress}/>}
                <p
                  className="ml-2 mt-3 italic quick-guide__import-stats-p">{`Imported ${importStats.completed} of ${importStats.total}`}</p>
                {isMembersImportFinished &&
                    <p className="ml-2 mt-3 italic quick-guide__import-stats-p">{`Import errors ${importStats.total - importStats.completed}`}</p>
                }
              </div>
            )}
          </div>
          {isMembersImportFinished && importStats && importStats.total > 0 && (
            <div>
              <div className="quick-guide__learn-more-div">
                <h2 className="quick-guide__learn-more-subtitle">Learn more about import errors</h2>
              </div>
              <div className="quick-guide__learn-more-div">
                <h2 className="quick-guide__learn-more-subtitle quick-guide__errors_link"
                    onClick={async () => {
                      await handleDownloadCsvFile();
                    }}>download your error report</h2>
              </div>
            </div>
          )}
        </Form>
      </FormikProvider>
    </section>
  )
}

export default HubSpotModal;