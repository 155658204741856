import React, {CSSProperties, useEffect, useRef} from "react";
import {Button, Modal as MUIModal} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

import {dataTestIds} from "../../../constants/data-test-ids";
import {parseBool} from "../parsers";
import './Modal.scss'

interface ModalProps {
    useHeader?: boolean;
    useFooter?: boolean;
    onConfirm?: () => void | Promise<any>;
    onDeny?: () => void;
    onClose?: () => void;
    show: boolean;
    haveTextArea?: boolean;
    userInput?: boolean | undefined;
    confirmBtnText?: string;
    denyBtnText?: string;
    children?: React.ReactElement;
    confirmButtonColor?: string;
    confirmButtonDisabled?: boolean;
    hideCloseButton?: boolean;
    hideConfirmButton?: boolean;
    disablePadding?: boolean;
    size?: "sm" | "lg" | "xl" | "xxl" | "xxxl" | undefined;
    hideClearIcon?: boolean;
    containerStyle?: CSSProperties;
}

export const Modal: React.FC<ModalProps> = ({
                                                      useHeader = true,
                                                      useFooter = true,
                                                      onConfirm,
                                                      onDeny,
                                                      onClose,
                                                      show,
                                                      haveTextArea,
                                                      userInput,
                                                      confirmBtnText,
                                                      denyBtnText,
                                                      children,
                                                      confirmButtonColor,
                                                      confirmButtonDisabled,
                                                      hideCloseButton,
                                                      hideConfirmButton,
                                                      disablePadding,
                                                      size,
                                                      hideClearIcon,
                                                      containerStyle,
                                                  }: ModalProps) => {

    const ref = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (ref && show) {
            ref.current?.focus();
        }
    }, [ref, show]);


    useEffect(() => {
        userInput && localStorage.setItem('userModalInput', `${userInput}`)
        document.addEventListener('keydown', escapeKeyHandler)
        return () => {
            localStorage.removeItem('userModalInput')
            document.removeEventListener("keydown", escapeKeyHandler)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInput])

    const escapeKeyHandler = (event: any) => {
        if (event.key === 'Escape') {
            closeModalClickOutside();
        }
    }

    function closeModalClickOutside() {
        const userInput = parseBool(localStorage.getItem('userModalInput') || '');
        if (!haveTextArea) {
            onClose?.()
        }
        if (!userInput) {
            onClose?.()
        }
    }

    return (
        <div ref={ref} onClick={closeModalClickOutside}>
            <MUIModal
                data-testid={dataTestIds.modal.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={show}
                onClose={onClose}
                disableEscapeKeyDown={true}
            >
                <>
                    <div className="modal">
                        <div className={`modal__container ${disablePadding ? 'p-0' : 'p-6'}`}
                             style={containerStyle ? containerStyle : {}}
                             onClick={e => {
                              // do not close modal if anything inside modal content is clicked
                              // if (!haveTextArea) {
                              //     e.stopPropagation();
                              //     onFocus()
                              // }
                              e.stopPropagation();
                        }}>
                            <div className={size ? `modal__${size}` : ''}>
                                {useHeader &&
                                    <div className="modal__header">
                                        {!hideClearIcon &&
                                            <ClearIcon fontSize={"small"}
                                                   onClick={onDeny ? onDeny : onClose}
                                                   data-testid={dataTestIds.modal.clearIcon}
                                                   style={disablePadding ? {top: '24px', right: '24px'} : {}}
                                                   className="modal__header-btn"
                                        />}
                                    </div>
                                }
                                <div className="modal__body">
                                    {children}
                                </div>
                                {useFooter &&
                                    <div className="modal__footer">
                                        {
                                            !hideCloseButton &&
                                            <Button
                                                className="modal__btn-cancel"
                                                onClick={() => onDeny ? onDeny() : onClose?.()}
                                                variant="contained"
                                            >
                                                <span className="modal__btn-label">{denyBtnText || 'Cancel'}</span>
                                            </Button>
                                        }
                                        {
                                            !hideConfirmButton &&
                                            <Button
                                                className="modal__btn-submit"
                                                type='submit'
                                                disabled={confirmButtonDisabled}
                                                onClick={() => onConfirm ? onConfirm() : onClose?.()}
                                                style={{
                                                    backgroundColor: confirmButtonColor ? confirmButtonColor : "inherit"
                                                }}
                                                variant="contained">
                                                <span className="modal__btn-label">{confirmBtnText || 'Submit'}</span>
                                            </Button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            </MUIModal>
        </div>
    )
}
