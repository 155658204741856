import * as React from 'react';
import {InputLabel} from "@mui/material";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import FormControl from '@mui/material/FormControl';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import LoadingSpinner from "../loading/LoadingSpinner";

export enum dropdownImpExpTypes {
    csvImport = 'Import .CSV',
    hubspotImport = 'Import data from HubSpot',
    exportData = 'Export All Members',
    exportNewScores = 'Export Members with New Scores'
}

type DropdownImportExportCsvType = {
    onChange: (type: dropdownImpExpTypes) => void;
    isLoading: boolean;
}

const DropdownImportExportCsv = ({onChange, isLoading}: DropdownImportExportCsvType) => {
    const [csvType, setCsvType] = React.useState('');

    return (
        <FormControl sx={{mr: 3, minWidth: 310, borderRadius: '14px'}}>
            <InputLabel sx={{fontSize: '14px'}} id="demo-simple-select-label">Import/Export members...</InputLabel>
            <Select
                value={csvType}
                disabled={isLoading}
                label='Import/Export members...'
                renderValue={(value) => (
                    <div className="flex items-center">
                        {value === dropdownImpExpTypes.csvImport || value === dropdownImpExpTypes.hubspotImport
                            ? <FileUploadIcon className="mr-2 text-xs text-dark-purple"/>
                            : <BrowserUpdatedIcon className="text-xs mr-2 text-dark-purple"/>}
                        <span className="text-sm text-dark-purple">{value}</span>
                        {isLoading && <div style={{marginLeft: '7px'}}><LoadingSpinner size={15} /></div>}
                    </div>
                )}
                sx={{p: 0, m: 0}}
            >
                <MenuItem value={dropdownImpExpTypes.csvImport}
                          onClick={() => {
                              setCsvType(dropdownImpExpTypes.csvImport)
                              onChange(dropdownImpExpTypes.csvImport)
                          }}
                >
                    <div className="flex items-center">
                        <FileUploadIcon
                            className="mr-2 text-xs text-dark-purple"
                        />
                        <span className="text-sm text-dark-purple">{dropdownImpExpTypes.csvImport}</span>
                    </div>
                </MenuItem>
                <MenuItem value={dropdownImpExpTypes.hubspotImport}
                          onClick={() => {
                              setCsvType(dropdownImpExpTypes.hubspotImport)
                              onChange(dropdownImpExpTypes.hubspotImport)
                          }}
                >
                    <div className="flex items-center">
                      <FileUploadIcon
                          className="mr-2 text-xs text-dark-purple"
                      />
                      <span className="text-sm text-dark-purple">{dropdownImpExpTypes.hubspotImport}</span>
                    </div>
                </MenuItem>
                <MenuItem value={dropdownImpExpTypes.exportData} className="flex items-center"
                          onClick={() => {
                              setCsvType(dropdownImpExpTypes.exportData)
                              onChange(dropdownImpExpTypes.exportData)
                          }}
                >
                    <div className="flex items-center">
                        <BrowserUpdatedIcon className="text-xs mr-2 text-dark-purple"/>
                        <span className="text-sm text-dark-purple">{dropdownImpExpTypes.exportData}</span>
                    </div>
                </MenuItem>
                <MenuItem value={dropdownImpExpTypes.exportNewScores} className="flex items-center"
                          onClick={() => {
                              setCsvType(dropdownImpExpTypes.exportNewScores)
                              onChange(dropdownImpExpTypes.exportNewScores)
                          }}
                >
                    <div className="flex items-center">
                        <BrowserUpdatedIcon className="text-xs mr-2 text-dark-purple"/>
                        <span className="text-sm text-dark-purple">{dropdownImpExpTypes.exportNewScores}</span>
                    </div>
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export default DropdownImportExportCsv;