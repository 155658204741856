import React, {useContext, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import axios, {AxiosError} from "axios";
import moment from "moment";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useQuery} from "@tanstack/react-query";

import DashboardComponent from "../../../components/DashboardComponent/DashboardComponent";
import DropdownImportExportCsv, {dropdownImpExpTypes} from "../../../components/Dropdowns/DropdownImportExportCsv";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import {Modal} from "../../../components/utils/Modal/Modal";
import {notifyError} from "../../../components/utils/ToastNotifications/Notifier";
import {ENDPOINTS, largeScreen, permissionsCatalog} from "../../../constants";
import {MemberOnTenant} from '../../../interfaces';
import {UserPermissionContext} from "../../../store/context/user-permission-context";
import PathContext from "../../../store/context/path-context";
import {useAPI} from "../../../utils/hooks/useAPI";
import {useDebounce} from "../../../utils/hooks/useDebounce";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import MembersList from "./MembersList/MembersList";
import AddMember from "./AddOrModifyMember/AddMember/AddMember";
import CSVModal from "./ImportCSVModal/ImportCSVModal";
import HubSpotModal, {ImportStatus} from "./ImportHubSpotDataModal/ImportHubSpotDataModal";
import './MemberManagement.scss';

export const icon_style = {
    fontSize: "1.1rem",
    width: "1.1rem",
    height: "1.1rem",
    paddingLeft: "0.2rem"
};

export type MouseOverMember = {
    id: number,
    isDisplayed: boolean
}

export const initialImportStatus: ImportStatus = {
    is_import_finished: false,
    total: 0,
    completed: 0
};

const MemberManagement = () => {
    const isLargeScreen = useIsMobile(largeScreen);
    const {userPermissions} = useContext(UserPermissionContext);
    const {member_id} = useParams();
    const [members, setMembers] = React.useState<MemberOnTenant[]>([{
        id: 0,
        company_name: '',
    }])

    const cancelSource = axios.CancelToken.source();
    const [memberModalOpened, setMemberModalOpened] = useState<boolean>(false);
    const [csvMembersModalOpened, setCsvMembersModalOpened] = useState<boolean>(false);
    const [hubspotModalOpened, setHubspotModalOpened] = useState<boolean>(false);
    const navigate = useNavigate();
    const api = useAPI(cancelSource);
    const location = useLocation();
    const [searchText, setSearchText] = useState('')
    const debounce = useDebounce(searchText, 1000)
    const [noMembers, setNoMembers] = useState<boolean>(false)
    const [initialLoad, setInitialLoad] = useState<boolean>(true)
    const {apiUrlProps: {tenant}} = useContext(PathContext)
    const [memberPreview, setMemberPreview] = useState<MouseOverMember | null>(null);
    const [importStats, setImportStats] = useState<ImportStatus>(initialImportStatus);
    const [shouldRefetch, setShouldRefetch] = useState(false);

    const {refetch: refetchExportedData, isFetching} = useQuery({
        queryKey: ['export-tenant-data'],
        queryFn: () => handleFetchingTenantData(),
        enabled: false
    })

    const {refetch: refetchChangedExportedData, isFetching: isChangedRefetching} = useQuery({
        queryKey: ['export-changed-tenant-data'],
        queryFn: () => handleFetchingChangedTenantData(),
        enabled: false
    })

    useEffect(() => {
        (async () => {
            await getMembersOnTenant();
        })();
        // eslint-disable-next-line
    }, [debounce]);

    // useEffect(() => {
    //     return () => {
    //         cancelSource.cancel('Cancelling request at cleanup');
    //     }
    //     // eslint-disable-next-line
    // }, []);

    const handleDropdownChange = (type: dropdownImpExpTypes) => {
        if (type === dropdownImpExpTypes.csvImport) {
            setCsvMembersModalOpened(true)
        }

        if (type === dropdownImpExpTypes.hubspotImport) {
            localStorage.removeItem('userModalInput')
            setImportStats(initialImportStatus);
            setShouldRefetch(true);
            setHubspotModalOpened(true)
        }

        if (type === dropdownImpExpTypes.exportData) {
            handleDownloadFile(type)
        }

        if (type === dropdownImpExpTypes.exportNewScores) {
            handleDownloadFile(type)
        }
    }

    const handleDownloadFile = async (type: dropdownImpExpTypes) => {
        try {
            const {data} = type === dropdownImpExpTypes.exportData
                ? await refetchExportedData()
                : await refetchChangedExportedData();


            if (data) {
                let blob = new Blob([(data as any).data], {
                    type: "application/pdf"
                });
                const fileURL = URL.createObjectURL(blob);
                const downloadLink = document.createElement('a');
                downloadLink.href = fileURL;

                downloadLink.download = type === dropdownImpExpTypes.exportData
                    ? `${tenant}_members_${moment().format('YYYY-MM-DD')}.csv`
                    : `${tenant}_members_changed_${moment().format('YYYY-MM-DD')}.csv`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            notifyError('Something went wrong.');
        }
    };

    const handleFetchingTenantData = () => {
        return api.get(ENDPOINTS.TENANTS.get_tenant_members_data)
    }

    const handleFetchingChangedTenantData = () => {
        return api.get(ENDPOINTS.TENANTS.get_tenant_changed_members_data)
    }

    const handleMemberAdded = async (memberID: number) => {
        await api.get(ENDPOINTS.MEMBERS.get_or_create_member)
            .then(async (response) => {
                await setMembers([...response.data]);
            })
            .catch((e: unknown) => {
                if ((e as AxiosError).response?.status === 403) {
                    navigate('/access-denied');
                    return;
                }
            })
        handleModalClose();
        navigate(`/admin/member-management/${memberID}`, {state: {memberID: memberID}})
    }

    async function getMembersOnTenant() {
        await api.get(ENDPOINTS.MEMBERS.get_members_by_search_text(searchText))
            .then(async response => {
                await setMembers(response.data)
                if (initialLoad && response.data.length === 0) {
                    setNoMembers(true);
                } else {
                    const memberId = member_id || response.data[0].id;
                    navigate(
                        `/admin/member-management/${memberId}`,
                        {state: {memberID: memberId}}
                    );
                }
                setInitialLoad(false);
            })
            .catch(() => {
                notifyError("Something went wrong, please try again or contact us at support@edisonmarks.com")
            })
    }

    const handleModalClose = () => {
        setMemberModalOpened(false);
        localStorage.removeItem('userModalInput')
    }

    const handleCsvModalClose = () => {
        setCsvMembersModalOpened(false);
    }

    const handleHubspotModalClose = () => {
        setHubspotModalOpened(false);
        localStorage.removeItem('userModalInput');
        setImportStats(initialImportStatus);
    }

    // TODO: style -> ?
    return (
        <DashboardComponent>
            <article className="member-management">
                <div className="member-management__headers">
                    <h5 className="member-management__title">Member Management</h5>
                    <div className="member-management__add-users">
                        {(userPermissions.includes(permissionsCatalog.create_members)) &&
                            <button className="member-management__add-btn"
                                    onClick={(e) => {
                                        localStorage.removeItem('userModalInput')
                                        e.preventDefault()
                                        setMemberModalOpened(true)
                                    }}>
                                <AddCircleOutlineIcon
                                    style={icon_style}
                                />
                                Add New Member</button>
                        }
                        {(userPermissions.includes(permissionsCatalog.create_members)) &&
                            <DropdownImportExportCsv isLoading={isChangedRefetching || isFetching}
                                                     onChange={handleDropdownChange}/>
                        }
                    </div>
                </div>
                {initialLoad ? <LoadingSpinner/> :
                    noMembers ?
                        <p className="member-management__no-members">
                            <span>There are no members yet.</span>
                            <span className="member-management__link-text"
                                  onClick={(e) => {
                                      localStorage.removeItem('userModalInput')
                                      e.preventDefault()
                                      setMemberModalOpened(true)
                                  }}>Add your first member</span>
                            <span>or</span>
                            <span className="member-management__link-text"
                                  onClick={(e) => {
                                      e.preventDefault()
                                      setCsvMembersModalOpened(true)
                                  }}>import a list in bulk.</span>
                        </p>
                        :
                        <>
                            <div className="member-management__search-member">
                                <input
                                    className="member-management__search-input member-management__search-input--focus"
                                    aria-label="member-management__search-input"
                                    type="search" value={searchText}
                                    placeholder="Enter company or domain to filter"
                                    onChange={e => {
                                        setSearchText(e.target.value)
                                    }}
                                />
                                <button className="member-management__search-btn">Filter</button>
                            </div>
                            <div
                                className={`${isLargeScreen ? "member-management__members-info-mobile" : "member-management__members-info"}`}>
                                <MembersList
                                    onMouseOver={(id: number) => setMemberPreview({id, isDisplayed: true})}
                                    onMouseOut={() => setMemberPreview(null)}
                                    filteredMembers={members}
                                    pathName={location.pathname}
                                />

                                <section className="member-management__outlet--wrapper">
                                    <div className="member-management__outlet">
                                        <Outlet/>
                                    </div>
                                    {/*{!!memberPreview && memberPreview.isDisplayed && (*/}
                                    {/*    <div className="member-management__outlet--hover">*/}
                                    {/*        <Outlet context={memberPreview}/>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </section>
                            </div>
                        </>
                }
                <Modal
                    useFooter={false}
                    onClose={handleModalClose}
                    show={memberModalOpened}
                    hideConfirmButton={true}
                    hideCloseButton={true}
                    haveTextArea={true}
                    onDeny={handleModalClose}
                    size="xxl">
                    <AddMember
                        handleSubmitResult={handleMemberAdded} title={'Add new member'}/>
                </Modal>
                <Modal
                    useFooter={false}
                    onClose={handleCsvModalClose}
                    show={csvMembersModalOpened}
                    hideConfirmButton={true}
                    hideCloseButton={true}
                    onDeny={handleCsvModalClose}
                    size="xl"
                >
                    <CSVModal handleCSVSubmit={handleCsvModalClose}/>
                </Modal>
                <Modal
                  useFooter={false}
                  onClose={handleHubspotModalClose}
                  show={hubspotModalOpened}
                  hideConfirmButton={true}
                  hideCloseButton={true}
                  haveTextArea={true}
                  onDeny={handleHubspotModalClose}
                  size="xxxl"
                  disablePadding={true}
                >
                   <HubSpotModal handleHubSpotSubmit={handleHubspotModalClose} importStats={importStats} setImportStats={setImportStats}
                   setShouldRefetch={setShouldRefetch} shouldRefetch={shouldRefetch}/>
                </Modal>
            </article>
        </DashboardComponent>
    )
}

export default MemberManagement;